import Vue from 'vue'
import App from './App'
import router from './router'
import 'vant/lib/button/style'
import 'vant/lib/tabbar/style'
import 'vant/lib/tabbar-item/style'
import 'vant/lib/swipe/style'
import 'vant/lib/swipe-item/style'
import 'vant/lib/swipe-cell/style'
import 'vant/lib/grid/style'
import 'vant/lib/grid-item/style'
import 'vant/lib/image/style'
import 'vant/lib/notice-bar/style'
import 'vant/lib/search/style'
import 'vant/lib/icon/style'
import 'vant/lib/cell-group/style'
import 'vant/lib/cell/style'
import 'vant/lib/nav-bar/style'
import 'vant/lib/sticky/style'
import 'vant/lib/form/style'
import 'vant/lib/field/style'
import 'vant/lib/button/style'
import 'vant/lib/tab/style'
import 'vant/lib/tabs/style'
import 'vant/lib/toast/style'
import 'vant/lib/popup/style'
import 'vant/lib/picker/style'
import 'vant/lib/tag/style'
import 'vant/lib/skeleton/style'
import 'vant/lib/empty/style'
import 'vant/lib/divider/style'
import 'vant/lib/checkbox-group/style'
import 'vant/lib/checkbox/style'
import 'vant/lib/radio-group/style'
import 'vant/lib/radio/style'
import 'vant/lib/dialog/style'
import * as echarts from 'echarts'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Swipe, SwipeItem } from 'vant'
import { Lazyload } from 'vant'
import { Tabbar, TabbarItem } from 'vant'
import { Grid, GridItem } from 'vant'
import { Image as VanImage } from 'vant'
import { NoticeBar } from 'vant'
import { Search } from 'vant'
import { Icon } from 'vant'
import { Cell, CellGroup } from 'vant'
import { NavBar } from 'vant'
import { Sticky } from 'vant'
import { Form } from 'vant'
import { Field } from 'vant'
import { Button } from 'vant'
import { Tab, Tabs } from 'vant'
import { Toast } from 'vant'
import { Popup } from 'vant'
import { Picker } from 'vant'
import { Tag } from 'vant'
import { Skeleton } from 'vant'
import { Empty } from 'vant'
import { Divider } from 'vant'
import { Popover } from 'vant'
import { RadioGroup, Radio } from 'vant'
import { Checkbox, CheckboxGroup } from 'vant'
import { Dialog } from 'vant'

Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(NoticeBar)
Vue.use(Search)
Vue.use(Icon)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(NavBar)
Vue.use(Sticky)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Picker)
Vue.use(Tag)
Vue.use(Skeleton)
Vue.use(Empty)
Vue.use(Divider)
Vue.use(Popover)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(VueAxios, axios)
Vue.use(Dialog)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

axios.defaults.timeout = 500000

router.beforeEach((to, from, next) => {
    // 判断当前路由以及路由的父路由中是否包含meta，meta里是否有requiredAuth
    // to.matched中包含当前路由以及父路由
    // 如果判断出to.matched中某个路由里有meta.requiredAuth
    if (to.matched.some(route => route.meta.requireLogin)) {
        // 判断是否已经登录
        let userId = localStorage.getItem('userInfo')
        if (userId) {
            next()
        } else {
            // 那就跳转到登录 并且添加query参数returnurl为to.path
            next({
                path: '/auth',
                query: {
                    rollback: to.path // 从哪来回哪去
                }
            })
        }
    } else {
        next()
    }
})

router.afterEach((to, from) => {
    if (window.__wxjs_is_wkwebview) {
        // IOS
        if (window.entryUrl == '' || window.entryUrl == undefined) {
            var url = `${FRONT_BASE}${to.fullPath}`
            window.entryUrl = url // 将后面的参数去除
            window.history.pushState({}, null, url)
        }
    }
})

new Vue({
    el: '#app',
    router,
    components: { App },
    template: '<App/>'
})
