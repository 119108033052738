import {Toast} from 'vant';
import router from "../router";

const checkLogin = function () {
    let token = localStorage.getItem('auth_token');
    if (token) {
        return token;
    }
    if (!token) {
        return router.push({path:'/auth'})
    }
}

export default checkLogin