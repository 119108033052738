<template>
    <div id="app">
        <router-view />
        <van-tabbar route active-color="#c4000f">
            <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
            <van-tabbar-item replace to="/market" icon="diamond-o">市场</van-tabbar-item>
            <van-tabbar-item replace to="/order" icon="balance-list-o">交易记录</van-tabbar-item>
            <!-- <van-tabbar-item v-if="serviceInfo.type === 1" replace to="/service" icon="service-o">客服中心</van-tabbar-item> -->
            <van-tabbar-item :url="getCustomerServiceUrl()" icon="service-o"
                >客服中心</van-tabbar-item
            >
            <van-tabbar-item replace to="/user" icon="contact">个人中心</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    import Api from './utils/api'

    export default {
        name: 'App',
        data() {
            return {
                serviceInfo: {},
                navShow: false
            }
        },
        created() {
            // this.getServiceInfo()
        },
        methods: {
            getCustomerServiceUrl() {
                const baseUrl =
                    'https://chat.zgsh.pro/chat/mobile?noCanClose=1&token=86ca9a8a026c90546432133c64489785'
                return `${baseUrl}&source=${encodeURIComponent(window.location.href)}`
            },
            getServiceInfo() {
                Api.get('/api/service')
                    .then(res => {
                        if (res.code === 200) {
                            this.serviceInfo = res.data
                        }
                    })
                    .catch(err => {
                        console.log('请求出错', JSON.stringify(err))
                    })
            },
            go(type, id = null) {
                this.navShow = !this.navShow
                switch (type) {
                    case 1:
                        this.$router.push({ path: '/news/info/' + id })
                        break
                    case 2:
                        this.$router.push({ path: '/market' })
                        break
                    case 3:
                        this.$router.push({ path: '/service' })
                        break
                    case 4:
                        this.$router.push({ path: '/news' })
                        break
                    case 5:
                        this.$router.push({ path: '/notice' })
                        break
                }
            }
        }
    }
</script>

<style>
    html,
    body {
        background: #ffffff;
        padding: 0;
        margin: 0;
    }

    #app {
        font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial,
            Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
    }

    .top-bar {
        width: 100%;
        height: 50px;
        overflow: hidden;
        /* //background: #fff; */
        position: fixed;
        top: 0;
        left: 0;
        z-index: 222;
    }

    .logo {
        width: 240px;
        height: auto;
        float: left;
        margin-left: 10px;
        font-weight: normal;
        overflow: hidden;
        margin-top: 10px;
    }

    .logo-image {
        width: 100px;
        /*height: 36px;*/
        float: left;
    }
    .logo-name {
        font-size: 1.3rem;
        color: #ffffff;
        float: left;
        margin-left: 10px;
        font-weight: 600;
        line-height: 40px;
        text-shadow: 2px 2px 3px black;
    }

    .nav-logo {
        width: 100px;
        height: auto;
        margin-top: 16px;
        margin-left: 6px;
    }

    .nav-logo img {
        width: 100%;
    }

    .menu-icon {
        float: right;
        margin-right: 10px;
        font-size: 2rem;
        line-height: 50px;
        color: #c4000f;
    }

    .nav-menu {
        height: auto;
        overflow: hidden;
        margin-top: 10px;
    }

    .nav-menu-item {
        width: 100%;
        height: 44px;
        overflow: hidden;
        color: #666;
        text-align: center;
        border-bottom: 1px solid #f1f1f1;
        line-height: 44px;
    }
    .tool-bar-nav {
        display: flex;
        height: 54px;
        width: 100%;
        max-width: 100%;
        position: relative;
        color: #fff;
        background: url('./assets/image/beijing-1.png') repeat;
    }
    .go-back {
        height: 44px;
        float: left;
        line-height: 50px;
        font-size: 1.2rem;
        color: #333333;
        margin-left: 10px;
    }
    .tool-bar-nav h1 {
        font-weight: normal;
        flex: 1;
        text-align: center;
        display: block;
        line-height: 2.25rem;
        color: #fff;
        font-size: 0.9rem;
        padding: 0;
    }
    .intoLogo {
        padding: 0.25rem 0px !important;
        display: block;
        overflow: hidden;
        line-height: 40px;
    }
    .intoLogo img {
        display: inline;
    }
    .tool-bar-nav h1 img {
        height: 2.8rem !important;
        display: inline-block !important;
    }
</style>
