import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'HomeIndex',
            meta: {
                requireLogin: false
            },
            component: resolve => require(['@/components/home/index'], resolve)
        },
        {
            path: '/market',
            name: 'MarkIndex',
            meta: {
                requireLogin: false
            },
            component: resolve => require(['@/components/market/index'], resolve)
        },
        {
            path: '/market/info/:id',
            name: 'MarkInfo',
            meta: {
                requireLogin: true
            },
            component: resolve => require(['@/components/market/info'], resolve)
        },
        {
            path: '/service',
            name: 'ServiceCenter',
            component: resolve => require(['@/components/service'], resolve)
        },
        {
            path: '/auth',
            component: resolve => require(['@/components/user/index'], resolve),
            children: [
                {
                    path: '',
                    name: 'AuthLogin',
                    component: resolve => require(['@/components/auth/login'], resolve)
                },
                {
                    path: 'register',
                    name: 'AuthRegister',
                    component: resolve => require(['@/components/auth/register'], resolve)
                }
            ]
        },
        {
            path: '/user',
            component: resolve => require(['@/components/user/index'], resolve),
            meta: {
                requireLogin: true
            },
            children: [
                {
                    path: '',
                    name: 'UserHome',
                    component: resolve => require(['@/components/user/home'], resolve)
                },
                {
                    path: 'password',
                    name: 'UpdatePassword',
                    component: resolve => require(['@/components/user/password'], resolve)
                },
                {
                    path: 'withdrawal/password',
                    name: 'UpdateWithdrawPassword',
                    component: resolve => require(['@/components/user/withdraw_password'], resolve)
                },
                {
                    path: 'wallet',
                    name: 'UserWallet',
                    component: resolve => require(['@/components/user/wallet'], resolve)
                },
                // {
                //     path:'recharge',
                //     name: 'recharge',
                //     component: resolve => require(['@/components/user/recharge'], resolve)
                // },
                {
                    path: 'withdrawal',
                    name: 'withdrawal',
                    component: resolve => require(['@/components/user/withdrawal'], resolve)
                }
                // {
                //     path:'bindbank',
                //     name: 'BindBank',
                //     component: resolve => require(['@/components/user/bank'], resolve)
                // }
            ]
        },
        {
            path: '/news',
            component: resolve => require(['@/components/news/index'], resolve),
            meta: {
                requireLogin: true
            },
            children: [
                {
                    path: '',
                    name: 'NewsList',
                    component: resolve => require(['@/components/news/list'], resolve)
                },
                {
                    path: 'info/:id',
                    name: 'NewsInfo',
                    component: resolve => require(['@/components/news/info'], resolve)
                }
            ]
        },
        {
            path: '/notice',
            component: resolve => require(['@/components/notice/index'], resolve),
            meta: {
                requireLogin: true
            },
            children: [
                {
                    path: '',
                    name: 'NoticeList',
                    component: resolve => require(['@/components/notice/list'], resolve)
                },
                {
                    path: 'info/:id',
                    name: 'NoticeInfo',
                    component: resolve => require(['@/components/notice/info'], resolve)
                }
            ]
        },
        {
            path: '/order',
            component: resolve => require(['@/components/news/index'], resolve),
            meta: {
                requireLogin: true
            },
            children: [
                {
                    path: '',
                    name: 'OrderList',
                    component: resolve => require(['@/components/order/list'], resolve)
                }
            ]
        },
        {
            path: '/recharge',
            component: resolve => require(['@/components/news/index'], resolve),
            meta: {
                requireLogin: true
            },
            children: [
                {
                    path: '',
                    name: 'RechargeOrderList',
                    component: resolve => require(['@/components/recharge/list'], resolve)
                }
            ]
        },
        {
            path: '/withdrawal',
            component: resolve => require(['@/components/withdrawal/index'], resolve),
            meta: {
                requireLogin: true
            },
            children: [
                {
                    path: '',
                    name: 'WithdrawalOrderList',
                    component: resolve => require(['@/components/withdrawal/list'], resolve)
                }
            ]
        }
    ]
})
