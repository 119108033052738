import axios from 'axios'
import { Toast } from 'vant'
import checkLogin from './auth'
import router from '../router'
import config from './config'
// create an axios instance
const Api = axios.create({
    baseURL: config.baseURL,
    timeout: 5000000
})

// request interceptor
Api.interceptors.request.use(
    config => {
        config.headers['Token'] = checkLogin()
        config.timeout = 5000000
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
Api.interceptors.response.use(
    response => {
        if (response.status === 401 || response.status === 419) {
            Toast.fail(response.data.message)
            router.push({ path: '/auth' })
        }
        if (response.data.code == -1) {
            localStorage.clear()
            router.push({ path: '/auth' })
        }
        if (response.data.code === 200 || response.data.code === 0) {
            return Promise.resolve(response.data)
        } else {
            Toast.fail(response.data.message)
            return Promise.reject(new Error(response.data.message))
        }
    },
    error => {
        if (error.response.status === 401 || error.response.status === 419) {
            let loginForm = localStorage.getItem('login_form')
            localStorage.clear()
            localStorage.setItem('login_form', loginForm)
            router.push({ path: '/auth' })
        }
        return Promise.reject(error)
    }
)

export default Api
